import React from 'react';
import logo from './logo.svg';
import './App.css';

function Error() {
    return (
        <div className="App">
            <h1>Oops! Page not found!</h1>
        </div>
    );
}

export default Error;
