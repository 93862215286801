import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Amplify, {Auth} from 'aws-amplify';
import  AWS  from 'aws-sdk'
import { AmplifySignOut, withAuthenticator } from '@aws-amplify/ui-react';

class Home extends Component {
  state = {
      endpoints: []
  }
  
  componentDidMount = async () => {

    const currCreds = await Auth.currentCredentials()
    const essentialCreds = Auth.essentialCredentials(currCreds)
    const dynamodb = new AWS.DynamoDB({"credentials": essentialCreds, "region": 'us-west-2' });
    const documentClient = new AWS.DynamoDB.DocumentClient({ service: dynamodb })
    let params = { TableName: process.env.REACT_APP_TABLE_NAME }
    let scanResults = [];
    let items;

		do {
				items = await documentClient.scan(params).promise();
				items.Items.forEach((item) => scanResults.push(item));
				params.ExclusiveStartKey = items.LastEvaluatedKey;
		} while (typeof items.LastEvaluatedKey != "undefined");

		const groupBy = key => array =>
		array.reduce((objectsByKeyValue, obj) => {
			const value = obj[key];
			objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
			return objectsByKeyValue;
		}, {});

		let endpoints = []

		const groupByResource = groupBy('resource')
		const groupByEnvironment = groupBy('environment')
		const resources = groupByResource(scanResults)

		Object.keys(resources).map((key, index) => {
			let env = []
			const environments = groupByEnvironment(resources[key])

			Object.keys(environments).map((key, index) => {
				env.push({
					"environment": key ,
					"items": environments[key]
				})
			})
			
			endpoints.push({
				"resource": key, 
				"items": env
			})

		});

		this.setState({"endpoints": endpoints})
  }
  render() {

		const data = this.state.endpoints;

        return (
            <div className="App">
                {/* <header className="App-header">
                    <AmplifySignOut />
                    <h2>My App Content</h2>
                </header> */}
                <div>
                    {data.map((res, iRes) => (
										<>
										<h2 key={iRes}>{res.resource}</h2>

										{res.items === undefined ? "loading..." : 
											res.items.map((env, iEnv) =>
											<> 
											<h3 key={iEnv}>{env.environment}</h3>
											<table key={iEnv+1}>
											<tr>
												<th>Name</th>
												{"description" in env.items[0] && env.items[0].description !==""  ? <th>Description</th> : ""} 
												{"url" in env.items[0] && env.items[0].url !=="" ? <th>URL</th> : ""} 
												<th>ARN</th>
											</tr>

											{env.items === undefined ? "loading..." : 
												env.items.map((row, iRow) =>
												<> 
												<tr key={row.arn}>
													<td>{row.name}</td>
													{"description" in row && row.description !=="" ? <td>{row.description}</td> : ""} 
													{"url" in row && env.items[0].description !=="" ? <td>{row.url}</td> : ""} 
													<td>{row.arn}</td>
												</tr>
												</>
												)
											}
											</table>
											</>
											)
										}
										</>
                    ))}
                </div> 
            </div>
            
        );

  }

    
}

export default withAuthenticator(Home);
