import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import Home from './Home';
import Error from './Error';

Amplify.configure(awsconfig);

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/" component={Home} exact />
                <Route component={Error} />
            </Switch>
        </Router>
    );
}

export default App;
